import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default class ConfirmationModal extends Component {
  static confirm({ title, message, yesLabel = 'Oui', noLabel = 'Non', onYes, onNo = () => {} }) {
    ConfirmationModal.__singletonRef.setState({
      show: true,
      title,
      message,
      yesLabel,
      noLabel,
      onYes,
      onNo,
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      show: false,
      title: '',
      message: '',
      yesLabel: '',
      noLabel: '',
      onYes: () => {},
      onNo: () => {},
    }
    ConfirmationModal.__singletonRef = this
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={() => {
          this.setState({ show: false })
        }}
      >
        <Modal.Header>{this.state.title}</Modal.Header>
        <Modal.Body>{this.state.message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              this.setState({ show: false })
              this.state.onNo()
            }}
          >
            {this.state.noLabel}
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              this.setState({ show: false })
              this.state.onYes()
            }}
          >
            {this.state.yesLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
