import { createStore } from 'redux'

let apiHost = ''
if (window.location.hostname.indexOf('localhost') !== -1) {
  apiHost = 'http://localhost:4000'
} else {
  apiHost = `https://api.${window.location.hostname}`
}

const initialState = {
  sidebarShow: true,
  selectedProductIds: localStorage.getItem('selectedProductIds')
    ? JSON.parse(localStorage.getItem('selectedProductIds'))
    : [],
  selectedAdvertiserIds: localStorage.getItem('selectedAdvertiserIds')
    ? JSON.parse(localStorage.getItem('selectedAdvertiserIds'))
    : [],

  breadcrumbLabels: {},
  apiHost,

  jwt_token: localStorage.getItem('jwt_token') ?? null,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
