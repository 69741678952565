import React, { Suspense, useEffect, useState } from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import UserStateComponent from './components/UserStateComponent.js'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './scss/style.scss'
import { useSelector } from 'react-redux'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ConfirmationModal from './views/common/ConfirmationModal.js'

const Login = React.lazy(() => import('./views/pages/Login.js'))
const Reset = React.lazy(() => import('./views/pages/Reset.js'))
const ChoosePassword = React.lazy(() => import('./views/pages/ChoosePassword.js'))
const Loader = React.lazy(() => import('./views/pages/Loader'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

export default function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children: [
        {
          exact: true,
          path: '/login',
          element: <Login />,
        },
        {
          path: '/reset',
          element: <Reset />,
        },
        {
          path: '/resetpassword/:token',
          element: <ChoosePassword />,
        },
        {
          path: '/join/:token',
          element: <ChoosePassword />,
        },
        {
          path: '*',
          element: <DefaultLayout />,
        },

        {
          index: true,
          element: <DefaultLayout />,
        },
      ],
    },
  ])

  return (
    <>
      <RouterProvider router={router} />

      <ToastContainer />
      <ConfirmationModal />
    </>
  )
}
const Root = () => {
  const [client, setClient] = useState(getApolloClient())

  const init_loading = useSelector((state) => state.init_loading)
  const jwt_token = useSelector((state) => state.jwt_token)

  useEffect(() => {
    setClient(getApolloClient(jwt_token))
  }, [jwt_token])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ApolloProvider client={client}>
          <Suspense fallback={loading}>
            <UserStateComponent />

            {init_loading && <Loader />}
            {!init_loading && <Outlet></Outlet>}
          </Suspense>
        </ApolloProvider>
      </LocalizationProvider>
    </>
  )
}

const getApolloClient = (jwtToken) => {
  let apiHost = ''
  if (window.location.hostname.indexOf('localhost') !== -1) {
    apiHost = 'http://localhost:4000'
  } else {
    apiHost = `https://api.${window.location.hostname}`
  }
  //apiHost = https://api.tls.toolso.io
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: `${apiHost}/graphql`,
      headers: {
        authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      },
    }),
  })
}
